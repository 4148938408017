<template>
    <b-row>
        <b-col xl="8" offset-xl="2" lg="10" offset-lg="1">
            <cluji-timeline :items="sortedItems">
                <!-- If participations are added again, uncomment this and everything should work fine
                <template #itemFooter="{item}">
                    <div v-if="item.participants">
                        <hr class="mt-2 mb-3"/>
                        <h4 class="timeline-title">Participants :</h4>
                        <ul v-if="item.participants.length">
                            <li v-for="participant in item.participants" :key="'participant_' + participant.id">
                                <user-name :user="participant"/>
                                <text-delete-button v-if="isCurrentlyLoggedInOrBureau(participant)"
                                                    @click.native="deleteParticipant(participant, item)"
                                                    class="ml-1"/>
                            </li>
                        </ul>
                        <p v-else>Personne pour l'instant</p>
                        <b-button v-if="canSubscribe(item)" variant="success" @click="subscribe(item)" size="sm">
                            M'inscrire pour cette étape
                        </b-button>
                    </div>
                </template>
                -->
            </cluji-timeline>

            <div v-if="canEdit(evenement)">
                <h2 v-b-tooltip.hover="'La date de fin est optionnelle (pour des étapes ponctuelles)'"
                    class="text-center">
                    Nouvelle étape &middot;
                    <small>
                        <font-awesome-icon icon="question-circle"/>
                    </small>
                </h2>
                <edit-etape-evenement :evenement="evenement" :callback="newEtape"/>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    const TextDeleteButton   = () => import('@/components/text_button/TextDeleteButton');
    const UserName           = () => import('@/components/UserName');
    const EditEtapeModal     = () => import( '@/components/modals/evenement/EditEtapeModal');
    const EditEtapeEvenement = () => import('./EditEtapeEvenement');
    const ClujiTimeline      = () => import('@/components/timeline/ClujiTimeline');

    import alert                                 from '@/util/alert';
    import {apiPath}                             from '@/util/http';
    import {format2Dates, formatDateTime}        from '@/util/date';
    import {numberSortAsc}                       from '@/util/sort';
    import {canEdit}                             from '@/util/evenement';
    import {isUser, isCurrentlyLoggedInOrBureau} from '@/util/auth';
    import {textUsername}                        from '@/util/text';

    import {mapState} from 'vuex';

    export default {
        name: "EvenementTimeline",
        components: {EditEtapeEvenement, ClujiTimeline, UserName, TextDeleteButton},
        props: {
            evenement: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            items: [],
        }),
        computed: {
            ...mapState({user: 'user'}),
            sortedItems() {
                return this.items.sort((a, b) => numberSortAsc(a.dateDebut, b.dateDebut));
            }
        },
        methods: {
            canEdit,
            isCurrentlyLoggedInOrBureau,
            canSubscribe(etape) {
                return isUser()
                       && this.evenement.participants.find(participant => participant.id === this.user.utilisateur.id) // Participe à l'événement
                       && !etape.participants.find(participant => participant.id === this.user.utilisateur.id); // Mais pas encore à l'étape
            },
            setupItems() {
                this.items = [
                    {
                        id: -1,
                        icon: 'hourglass-start',
                        iconClass: 'success',
                        nom: 'Début',
                        date: formatDateTime(this.evenement.dateDebut),
                        dateDebut: this.evenement.dateDebut
                    },
                    ...this.evenement.etapes.map(etape => ({
                        ...etape,
                        icon: 'hourglass-half',
                        iconClass: 'info',
                        date: (etape.dateFin && !etape.dateFin.isSame(etape.dateDebut))
                            ? format2Dates(etape.dateDebut, etape.dateFin)
                            : formatDateTime(etape.dateDebut),
                        editHandler: canEdit(this.evenement) ? () => this.editEtape(etape) : null,
                        deleteHandler: canEdit(this.evenement) ? () => this.deleteEtape(etape) : null
                    })),
                    {
                        id: 0,
                        icon: 'hourglass-end',
                        iconClass: 'danger',
                        nom: 'Fin',
                        date: formatDateTime(this.evenement.dateFin),
                        dateDebut: this.evenement.dateFin,
                    }
                ];
            },
            editEtape(etape) {
                this.$store.dispatch('modal/create', {
                    component: EditEtapeModal,
                    props: {
                        etape: etape,
                        evenement: this.evenement,
                        callback: form => {
                            alert.loading();
                            this.axios.post(apiPath('edit_etape', {etape: etape.id}), form)
                                .then(() => {
                                    this.$toaster.success('Étape modifiée');
                                    this.$emit('updateEtapes');
                                })
                                .catch(() => this.$toaster.error("Impossible de modifier l'étape"))
                                .finally(alert.stopLoading);
                        }
                    }
                });
            },
            deleteEtape(etape) {
                alert.confirm("Supprimer l'étape " + etape.nom + ' ?', () => {
                    this.axios.delete(apiPath('delete_etape', {etape: etape.id}))
                        .then(() => {
                            this.$toaster.success('Étape supprimée');
                            this.$emit('updateEtapes');
                        })
                        .catch(() => this.$toaster.error("Impossible de supprimer l'étape"));
                });
            },
            newEtape(form) {
                alert.loading();
                this.axios.post(apiPath('new_etape_for_evenement', {evenement: this.evenement.id}), form)
                    .then(() => {
                        this.$toaster.success('Étape créée');
                        this.$emit('updateEtapes');
                    })
                    .catch(() => this.$toaster.error("Impossible de créer l'étape"))
                    .finally(alert.stopLoading);
            },
            deleteParticipant(participant, etape) {
                alert.confirm(
                    'Supprimer la participation de ' + textUsername(participant) + ' ?',
                    () => this.axios
                        .delete(apiPath('delete_participation_etape_event', {
                            etape: etape.id,
                            participant: participant.id
                        }))
                        .then(() => {
                            this.$toaster.success('Participation supprimée');
                            this.$emit('updateEtapes');
                        })
                        .catch(() => this.$toaster.error('Impossible de supprimer la participation'))
                        .finally(alert.stopLoading)
                );
            },
            subscribe(etape) {
                alert.loading();
                this.axios.post(apiPath('subscribe_etape_event', {etape: etape.id}))
                    .then(() => {
                        this.$toaster.success('Participation enregistrée');
                        this.$emit('updateEtapes');
                    })
                    .catch(() => this.$toaster.error("Impossible d'enregistrer votre participation"))
                    .finally(alert.stopLoading);
            }
        },
        watch: {
            "evenement.etapes"() {
                this.setupItems();
            }
        },
        mounted() {
            this.setupItems();
        }
    }
</script>
